export default defineNuxtPlugin(() => {
  // Ne s'exécute que côté client
  if (!process.client) return;

  const searchParams = window.location.search;

  // Si aucun paramètre de recherche, supprimer les données stockées et quitter
  if (!searchParams) {
    localStorage.removeItem("utmParams");
    return;
  }

  const urlParams = new URLSearchParams(searchParams);

  // Mapper les paramètres UTM vers XTRA
  const utmToXtraMap = {
    utm_source: "xtra_source",
    utm_medium: "xtra_medium",
    utm_campaign: "xtra_campaign",
  };

  // Transformer les paramètres UTM en paramètres XTRA
  let hasUtmCampaign = false;

  Object.entries(utmToXtraMap).forEach(([utmParam, xtraParam]) => {
    const paramValue = urlParams.get(utmParam);

    if (paramValue) {
      urlParams.delete(utmParam);
      urlParams.append(xtraParam, paramValue);

      if (utmParam === "utm_campaign") {
        hasUtmCampaign = true;
      }
    }
  });

  // Déterminer quoi stocker dans localStorage
  const paramsToStore = hasUtmCampaign ? "?" + urlParams.toString() : searchParams;

  localStorage.setItem("utmParams", paramsToStore);
});
