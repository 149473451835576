import { useGtm } from "@gtm-support/vue-gtm";

export const useGtmClickTracking = () => {
  const gtm = useGtm();

  const handleClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const link = target.closest("a.btn-link, a.extracadabra-button");
    if (!link) return;

    const href = (link as HTMLAnchorElement).href || "";
    if (href.includes("signup") || href.includes("creer-un-compte")) {
      gtm?.push({
        event: "potential_sign_up",
      });

      console.log("GTM event triggered: potential_sign_up", {
        url: href,
        dataLayer: window.dataLayer,
      });
    }
  };

  onMounted(() => {
    document.addEventListener("click", handleClick);
  });

  onBeforeUnmount(() => {
    document.removeEventListener("click", handleClick);
  });
};
