export const utm = {
  campaign: "&xtra_campaign=",
  source: "?xtra_source=",
  etab: {
    download_app: "www_recruteur_download_app",
    web_app: "www_recruteur_push_web_app",
    mobile_app: "www_recruteur_push_mobile_app",
    mobile_compact_app: "www_recruteur_push_mobile_compact_app",
    account: ["www_recruteur_create_account", "www_recruteur_connect_account"],
  },
  candidates: {
    download_app: "www_candidat_download_app",
    web_app: "www_candidat_push_web_app",
    mobile_app: "www_candidat_push_mobile_app",
    mobile_compact_app: "www_candidat_push_mobile_compact_app",
    account: ["www_candidat_create_account", "www_candidat_connect_account"],
  },
};
