import { defineNuxtPlugin } from "#app";

export default defineNuxtPlugin(({ $router }) => {
  $router.afterEach(() => {
    const utmCampaign = localStorage.getItem("utmParams") as string;
    const observer = new MutationObserver(() => {
      document
        .querySelectorAll<HTMLIFrameElement>('iframe[src*="typeform.com"]')
        .forEach((iframe) => {
          const url = new URL(iframe.src);
          if (utmCampaign) {
            iframe.src = url.origin + url.pathname + utmCampaign;
          } else {
            iframe.src = url.origin + url.pathname + "?xtra_campaign=organic";
          }
        });
    });

    observer.observe(document.body, { childList: true, subtree: true });
    setTimeout(() => observer.disconnect(), 5000);
  });
});
