export const simulatorCaAe = {
  slug: "auto-entrepreneur",
  title: "Auto-entrepreneur, combien pourrais-tu gagner en tant que",
  title_flip_list:
    "<li><span>Chef de rang</span> ?</li> <li><span>Serveur</span> ?</li> <li><span>Barman</span> ?</li> <li><span>Runner</span> ?</li><li><span>Réceptionniste</span> ?</li>",
  step: {
    group: {
      title: "Choisis ton domaine",
    },
    qualif: {
      title: "Choisis ton métier",
      placeholder: "Sélectionne ton métier",
    },
    time: {
      title: "Choisis le nombre d’heures",
      hour: "h",
      workPerWeek: "travaillées par semaine",
    },
  },
  result: {
    title: {
      part1: "Si tu travailles en tant que <br />",
      part2: "heure(s) / semaine",
      part3: "tu pourras gagner entre :",
    },
    rate: {
      and: "et",
      euroPerMonth: "euros / mois",
      socialContribution: "Estimation de tes côtisations sociales*",
      between: "Entre",
      or: "ou",
    },
  },
  cta: {
    submit: "Lancer la simulation",
    subscribe: "M’inscrire gratuitement",
    newSimulator: "Faire une nouvelle simulation",
  },
  faqs: {
    title: "FAQ : vos questions les plus posées",
    list: [
      {
        question: "Comment calculer le net pour un auto-entrepreneur ?",
        answer:
          "Voici comment calculer son revenu net en tant qu’auto-entrepreneur :<br /><br />Chiffre d’affaires - Cotisations sociales URSSAF - Impôts (si applicables) = Revenu net.<br />En utilisant un outil de <strong>simulation de revenus auto-entrepreneur</strong>, vous pouvez facilement estimer votre chiffre d’affaires après déduction des charges sociales.",
      },
      {
        question: "Quel est le salaire maximum d'un auto-entrepreneur ?",
        answer:
          'Il n’y a pas de salaire maximum en tant qu’auto-entrepreneur, mais votre chiffre d’affaires annuel est plafonné. En 2024, ces plafonds sont de :<br /><br /><ul><li><strong>77 700 €</strong> pour les prestations de services</li><li><strong>188 700 €</strong> pour la vente de marchandises</li></ul>Au-delà de ces montants, vous sortez du régime auto-entrepreneur. Votre "salaire" dépend donc de votre chiffre d’affaires, une fois déduites les cotisations URSSAF et les impôts.',
      },
      {
        question: "Est-ce que je cotise pour la retraite en étant qu'auto-entrepreneur ?",
        answer:
          "Oui, en tant qu'auto-entrepreneur, vous cotisez pour la retraite via les <strong>cotisations URSSAF</strong>. Ces cotisations sociales incluent une part destinée à la retraite de base et à la retraite complémentaire. Cependant, le montant de vos droits dépend directement de votre <strong>chiffre d’affaires</strong> : plus il est élevé, plus vous accumulez de trimestres pour valider votre retraite.",
      },
    ],
  },
  footer: {
    legals: `*Les côtisations sociales des auto-entrepreneurs en restauration représentent un
      pourcentage de leur chiffre d’affaires. Au 1er janvier 2025, elles s’élèvent à 24.1%
      couvrant la santé, la retraite et les allocations familiales.`,
  },
};
