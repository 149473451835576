import type { AsyncDataOptions } from "#app";
import HttpFactory from "~/repository/factory";
import type { APIResponse } from "~/models/Api";

interface SecretConfig {
  password: string;
}

class PasswordsModule extends HttpFactory {
  private resourcePath = "/api/secret-config";

  async getPasswords(asyncDataOptions?: AsyncDataOptions<APIResponse<SecretConfig>>) {
    return await useAsyncData<APIResponse<SecretConfig>>(
      "secretConfig",
      () => {
        return this.call({
          method: "GET",
          url: this.resourcePath,
        });
      },
      asyncDataOptions,
    );
  }
}

export default PasswordsModule;
