import { applications } from "./applications";
import { routes } from "~/constants";

const utmLinkFooter = "?xtra_source=website&xtra_medium=cta&xtra_campaign=www_footer";
export const footer = {
  menu: [
    {
      title: "jobs",
      items: [
        {
          name: "Toutes les offres d'emploi",
          url: "https://jobs.extracadabra.com/?xtra_source=website&xtra_medium=cta&xtra_campaign=www_footer",
          external: true,
        },
        {
          name: "Restauration",
          url: "https://jobs.extracadabra.com/emploi-restauration?xtra_source=website&xtra_medium=cta&xtra_campaign=www_footer_push_qualifications",
          external: true,
        },
        {
          name: "Restauration collective",
          url: "https://jobs.extracadabra.com/restauration-collective?xtra_source=website&xtra_medium=cta&xtra_campaign=www_footer_push_qualifications",
          external: true,
        },
        {
          name: "Évènementiel",
          url: "https://jobs.extracadabra.com/recherche?q=13&q=14&q=2&q=43&q=26&q=32&q=100&q=3&q=25&q=12&q=8&q=11&q=49&q=94&q=67&q=5&q=28&q=9&q=64&q=42&q=101&q=53&q=95&q=96&q=98&q=38&q=75&q=99&q=87&q=74&q=62&q=92&q=10&q=118&q=33&q=79&q=40&q=93&q=7&q=97&q=41&q=45&q=111&q=1&cp=&xtra_source=website&xtra_medium=cta&xtra_campaign=www_footer_push_qualifications",
          external: true,
        },
        {
          name: "Hôtellerie",
          url: "https://jobs.extracadabra.com/hotellerie-restauration?xtra_source=website&xtra_medium=cta&xtra_campaign=www_footer_push_qualifications",
          external: true,
        },
        {
          name: "Logistique",
          url: "https://jobs.extracadabra.com/vente-logistique?xtra_source=website&xtra_medium=cta&xtra_campaign=www_footer_push_qualifications",
          external: true,
        },
        {
          name: "Vente",
          url: "https://jobs.extracadabra.com/vente-logistique?xtra_source=website&xtra_medium=cta&xtra_campaign=www_footer_push_qualifications",
          external: true,
        },
      ],
    },
    {
      title: "recrutement",
      items: [
        {
          name: "Recruter en Île-de-France",
          url: `${routes.recruteurUrl.index}${utmLinkFooter}`,
          external: false,
        },
        {
          name: "Recruter à Lyon",
          url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.lpEtabLyon}${utmLinkFooter}`,
          external: false,
        },
        {
          name: "Café et restaurant indépendant",
          url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.bu.cafeRestaurant}${utmLinkFooter}`,
          external: false,
        },
        {
          name: "Groupe d'hôtels et restaurants",
          url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.bu.hotellerie}${utmLinkFooter}`,
          external: false,
        },
        {
          name: "Restauration collective",
          url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.bu.restaurationCollective}${utmLinkFooter}`,
          external: false,
        },
        {
          name: "Traiteur évènementiel",
          url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.bu.traiteurEvenementiel}${utmLinkFooter}`,
          external: false,
        },
        {
          name: "Commerce de bouche",
          url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.bu.commerceDeBouche}${utmLinkFooter}`,
          external: false,
        },
        {
          name: "Logistique et Vente",
          url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.bu.logistiqueVente}${utmLinkFooter}`,
          external: false,
        },
        {
          name: "FAQ",
          url: `${routes.faqUrl.recruteurs}${utmLinkFooter}`,
          external: false,
        },
      ],
    },
    {
      title: "candidat",
      items: [
        {
          name: "Travailler",
          url: `${routes.candidatUrl.index}${utmLinkFooter}`,
          external: false,
        },
        {
          name: "Travailler en Intérim",
          url: `${routes.candidatUrl.index}/${routes.candidatUrl.lpInterim}${utmLinkFooter}`,
          external: false,
        },
        {
          name: "Travailler dans L'hotellerie",
          url: `${routes.candidatUrl.index}/${routes.candidatUrl.lpHotellerie}${utmLinkFooter}`,
          external: false,
        },
        {
          name: "Simulateur de chiffre d'affaire",
          url: `${routes.simulatorCaAe}${utmLinkFooter}`,
          external: false,
        },
        {
          name: "Travailler à Lyon",
          url: `${routes.candidatUrl.index}/${routes.candidatUrl.hidfLyon}${utmLinkFooter}`,
          external: false,
        },
        {
          name: "Blog",
          url: `${routes.blogHomeUrl}${utmLinkFooter}`,
          external: false,
        },
        {
          name: "FAQ",
          url: `${routes.faqUrl.candidats}${utmLinkFooter}`,
          external: false,
        },
      ],
    },
  ],
  socials: {
    title: "Suivez-nous sur les réseaux sociaux",
    items: [
      {
        name: "facebook",
        url: "https://www.facebook.com/extracadabra",
      },
      {
        name: "tiktok",
        url: "https://www.tiktok.com/@extracadabra",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/extracadabra/",
      },
      {
        name: "linkedin",
        url: "https://www.linkedin.com/company/extracadabra/",
      },
    ],
  },
  apps: [
    {
      title: "TÉLÉCHARGER L’APPLICATION RECRUTEUR",
      items: [
        {
          src: applications.playStore.logo,
          alt: "Application Android Extracadabra - Recruteur",
          url: applications.playStore.etab,
        },
        {
          src: applications.appleStore.logo,
          alt: "Application iOS Extracadabra - Recruteur",
          url: applications.appleStore.etab,
        },
      ],
    },
    {
      title: "TÉLÉCHARGER L’APPLICATION CANDIDAT",
      items: [
        {
          src: applications.playStore.logo,
          alt: "Application Android Extracadabra - Candidat",
          url: applications.playStore.candidates,
        },
        {
          src: applications.appleStore.logo,
          alt: "Application iOS Extracadabra - Candidat",
          url: applications.appleStore.candidates,
        },
      ],
    },
  ],
  legals: {
    cgu: {
      name: "CGU",
      url: routes.cgu,
    },
    confidentiality: {
      name: "Charte de confidentialité",
      url: routes.confidentiality,
    },
  },
  company: {
    contact: {
      name: "Contact",
      url: "mailto:contact@extracadabra.com",
    },
    joinUs: {
      name: "Rejoins-nous",
      url: "https://welcometothejungle.com/fr/companies/extracadabra",
    },
    press: {
      name: "Medias",
      url: `${routes.mediaPressUrl}${utmLinkFooter}`,
    },
    review: {
      name: "Avis",
      url: `${routes.avisUrl}${utmLinkFooter}`,
    },
  },
};
