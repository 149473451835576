import type { FetchError } from "ofetch";
import type { AsyncDataOptions } from "#app";
import type { APIParams, APIResponse } from "~/models/Api";
import HttpFactory from "~/repository/factory";

// Définition du modèle pour les charges patronales
export interface PayrollTax {
  id: number;
  min_brut: number;
  max_brut: number | null;
  taux: number;
  createdAt: string;
  updatedAt: string;
}

class PayrollTaxesModule extends HttpFactory {
  // Chemin pour correspondre à la route Strapi
  private resourcePath = "/api/payroll-taxes";

  /**
   * Récupère la liste complète des charges patronales
   */
  async list<T = APIResponse<PayrollTax[]>, R = T>(
    { filters, pagination, populate, sort, fields, locale }: APIParams = {
      filters: {},
      populate: {},
    },
    asyncDataOptions?: AsyncDataOptions<T, R>,
  ) {
    return await useAsyncData<T, FetchError, R>(
      "payroll-taxes",
      () => {
        const params = {
          populate,
          filters,
          pagination,
          sort,
          fields,
          locale,
        };

        return this.call({
          method: "GET",
          url: this.resourcePath,
          fetchOptions: {
            params,
          },
        });
      },
      asyncDataOptions,
    );
  }

  /**
   * Récupère tous les taux de charges patronales
   */
  async getTaxes<T = APIResponse<PayrollTax[]>, R = T>(
    asyncDataOptions?: AsyncDataOptions<T, R>,
  ) {
    return await this.list({}, asyncDataOptions);
  }

  /**
   * Trouve le taux de charge patronale applicable pour un salaire brut donné
   * @param salaireBrut Le salaire brut horaire
   * @returns Le taux applicable (en décimal, ex: 0.27 pour 27%)
   */
  async findApplicableTaxRate(salaireBrut: number): Promise<number> {
    try {
      console.log("🔍 Recherche du taux pour salaire brut:", salaireBrut);

      // Si le salaire est vide ou invalide, utiliser le taux par défaut
      if (!salaireBrut || isNaN(salaireBrut) || salaireBrut <= 0) {
        console.log("⚠️ Salaire invalide, utilisation du taux par défaut (27%)");
        return 0.27;
      }

      // Récupération depuis l'API Strapi
      const { data: response } = await this.getTaxes();

      // Vérification que nous avons bien des données
      if (
        !response?.value?.data ||
        !Array.isArray(response.value.data) ||
        response.value.data.length === 0
      ) {
        console.warn(
          "❌ Pas de données valides de l'API, utilisation du taux par défaut (27%)",
        );
        return 0.27;
      }

      console.log("📊 Données API disponibles:", response.value.data.length, "tranches");

      // Essayer de trouver la tranche correspondante
      const applicableTax = response.value.data.find((tax) => {
        // Vérifier que l'objet tax et ses attributs existent
        if (!tax || !tax) {
          console.warn("⚠️ Objet tax ou attributs manquants:", tax);
          return false;
        }

        // Obtenir les valeurs en s'assurant qu'elles sont des nombres
        const minBrut = parseFloat(tax.min_brut);
        const maxBrut = tax.max_brut === null ? null : parseFloat(tax.max_brut);

        // Vérifier que les valeurs sont valides
        if (isNaN(minBrut) || (maxBrut !== null && isNaN(maxBrut))) {
          console.warn("⚠️ Valeurs min/max invalides:", tax);
          return false;
        }

        // Si c'est la dernière tranche (max_brut est null)
        if (maxBrut === null) {
          const isMatch = salaireBrut >= minBrut;
          console.log(
            `- Vérification tranche: min=${minBrut}, max=null - ${
              isMatch ? "✅ correspond" : "❌ ne correspond pas"
            }`,
          );
          return isMatch;
        }

        // Pour les autres tranches
        const isMatch = salaireBrut >= minBrut && salaireBrut <= maxBrut;
        console.log(
          `- Vérification tranche: min=${minBrut}, max=${maxBrut} - ${
            isMatch ? "✅ correspond" : "❌ ne correspond pas"
          }`,
        );
        return isMatch;
      });

      if (applicableTax) {
        const taux = parseFloat(applicableTax.taux);
        console.log(`✅ Tranche API trouvée: taux = ${taux}%`);
        return taux / 100;
      }

      console.warn("❌ Aucune tranche trouvée dans l'API pour le salaire:", salaireBrut);
      return 0.27;
    } catch (error) {
      console.error("❌ Erreur lors de la récupération du taux:", error);
      return 0.27;
    }
  }
}

export default PayrollTaxesModule;
