import { routes } from "~/constants";

const utmTrackerHub =
  "?xtra_source=website&xtra_medium=link&xtra_campaign=www_hub_client";
export const hubClients = {
  title: "Le candidat ou le job parfait pour vous, en un claquement de doigt !",
  subtitle: `En <a href="${routes.jobsUrl.extra}" target="_blank">Extra</a>, saisonnier, <a href="${routes.candidatUrl.index}/${routes.candidatUrl.lpInterim}${utmTrackerHub}">intérim</a> ou CDI, Extracadabra propose les meilleures offres et profils en <a href="${routes.jobsUrl.restauration}${utmTrackerHub}" target="_blank">restauration</a>, <a href="${routes.jobsUrl.hotel}${utmTrackerHub}" target="_blank">hôtellerie</a>, <a href="${routes.jobsUrl.evenementiel}${utmTrackerHub}" target="_blank">évènementiel</a>, <a href="${routes.jobsUrl.logistic}${utmTrackerHub}" target="_blank">vente et logistique</a> à <a href="${routes.jobsUrl.paris}${utmTrackerHub}" target="_blank">Paris</a> & <a href="${routes.candidatUrl.index}/${routes.candidatUrl.hidfLyon}${utmTrackerHub}">Lyon</a> !`,
};

export { routes };
