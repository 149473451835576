<script setup type="ts">
useGtmClickTracking();
useHead({
  htmlAttrs: {
    lang: 'fr'
  },
  meta:{
    name: 'viewport',
    content: 'width=device-width, initial-scale=1, maximum-scale=1'
  }
})
</script>
<template>
  <div>
    <NuxtPage />
    <div id="portal-popup"></div>
  </div>
</template>
