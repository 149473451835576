export const partnerItem = {
  CANDIDATES: [
    {
      brandName: "Alan",
      logoSrc: "/img/partenaires/candidats/alan.png",
      subtitleColor: "#6160f5",
      ctaUrl: "https://alan.com/fr-fr/p/extracadabra",
      infos: {
        title: "Le partenaire santé qui prévient, assure et accompagne au quotidien",
        text: "<p>Alan, c’est l’assurance santé 100% en ligne la plus complète du marché : tu es couvert en 5 minutes top chrono, sans carence et Alan s’occupe même de la transition pour toi !</p><p>Remboursements de tes soins en moins de 24h, accès 7/7 à plus de 80 professionnels de santé par chat et téléconsultations, service client ultra-réactif, nouvelles lunettes ou lentilles sans bouger de votre canapé… Tout ça via une seule app. Simple, non ?</p><p>Avec Alan, prends soin de toi facilement.</p>",
        promo: {
          code: "Profite d’1 mois offert sur ta complémentaire santé pour commencer en douceur.",
          ctaLabel: "Découvre l'offre",
        },
      },
    },
    {
      brandName: "Blank",
      logoSrc: "/img/partenaires/candidats/blank.png",
      subtitleColor: "#3c94ff",
      ctaUrl:
        "https://landing.blank.app/extracadabra?xtra_medium=partnership&xtra_source=espace_partenaire&xtra_campaign=extracadabra&utm_content=",
      infos: {
        title: "Le compte pro qui libère les indépendants",
        text: "Facturation, déclarations, conseils, tout est géré au sein de l’application Blank. Grâce à ton compte pro et les différents avantages offerts, tu pourras mieux gérer ta microentreprise et donc exercer plus sereinement. En plus, leur service client est joignable par téléphone ou chat à tout moment !",
        promo: {
          code: "Profite de 100€ offerts en ouvrant ton compte *",
          ctaLabel: "Découvre l'offre",
        },
        extraInfos: {
          title: "* Quelles sont les modalités ?",
          text: "Une fois inscrit et ton compte créé, tu recevras automatiquement 50€ sur ton nouveau compte (crédité à la fin du mois d’inscription). Tu devras ensuite effectuer une mission dans les 60 jours après ton inscription sur Extracadabra pour recevoir les 50€ restants. Une fois ta mission réalisée, ton compte sera alors crédité !",
        },
      },
    },
    {
      brandName: "Superindep.fr",
      logoSrc: "/img/partenaires/candidats/superindep.png",
      subtitleColor: "#1d0f68",
      ctaUrl:
        "https://creation.superindep.fr/?sponsor=extracadabra&activities=waiter&activities=consultantlogistic",
      infos: {
        title: "Une simplicité dans vos démarches administratives",
        text: "<p>Un problème de création ou de suivi sur tes déclarations d’auto-entrepreneur, Superindep est solution qu’il te faut !</p><p>Simplifie tes déclarations et automatise grâce à la synchronisation de ton compte URSSAF. Avec Superindep, une analyse quotidienne et un suivi sera disponible à tout moment !<p>",
        promo: {
          code: "Pour seulement 15€TTC, obtiens des mails de formation sur la micro-entreprise, ton siret en ligne automatiquement et un logiciel de facturation !",
          ctaLabel: "Découvre l'offre",
        },
      },
    },
    {
      brandName: "Cautioneo",
      logoSrc: "/img/partenaires/candidats/cautioneo.png",
      subtitleColor: "#4842e3",
      ctaUrl: "https://lp.cautioneo.com/extracadabra/",
      infos: {
        title: "Le meilleur des garants pour les indépendants",
        text: "<p>Cautioneo se porte garant de ton dossier de location, et te permet d’avoir un dossier béton & rassurant pour ton futur propriétaire.</p><p>L’accès au logement est l’un des sujets les plus récurrents pour les Freelances en France, si bien que 52% d’entre eux déclarent avoir des difficultés à se loger.</p><p>Contrairement aux propriétaires qui demandent 2 à 3 années de bilans comptables, Cautionéo étudiera ton dossier après 6 mois d’activité et prendra en compte l’ensemble de tes revenus et de ton épargne.</p>",
        promo: {
          code: "Bénéficie de 2 mois offerts avec le code EXTRA_GARANT",
          ctaLabel: "Découvre l'offre",
        },
      },
    },
    {
      brandName: "En voitue simone",
      logoSrc: "/img/partenaires/candidats/en-voiture-simone.png",
      subtitleColor: "#6E949A",
      ctaUrl: "https://www.envoituresimone.com/partenaires/extracadabra",
      infos: {
        title: "Une envie de liberté dans tes déplacements ?",
        text: "En Voiture Simone te met en relation avec des enseignants diplômés d’état pour passer ton permis simplement. Cela te permet de choisir ton moniteur et d'être flexible selon ton emploi du temps. Passe ton permis en temps record à prix malin grâce au partenariat En Voiture Simone & Extracadabra !",
        promo: {
          code: "Profite d'une réduction avec le code EXTRACADABRA !",
          ctaLabel: "Découvre l'offre",
        },
      },
    },
    {
      brandName: "Duthilleul & Minart",
      logoSrc: "/img/partenaires/candidats/duthilleul-et-minart.webp",
      subtitleColor: "#0072C6",
      ctaUrl: "http://www.duthilleuletminart.fr/",
      infos: {
        title: "Le plus ancien spécialiste du vêtement professionnel",
        text: "Tu es accueilli au magasin en plein centre de Paris. Choisis la tenue adaptée à ton métier et à ta taille. Les vendeuses proposent un choix important parmi les plus grandes marques, selon tes attentes, ton poste et ton budget, ainsi qu'un service de broderie sur place.",
        promo: {
          code: "Avec le code EXTRA, bénéficie d'une réduction de 13% sur ta commande (hors promotions)",
          ctaLabel: "Découvre l'offre",
        },
        extraInfos: {
          title: "Informations pratiques",
          text: "Accès facile de 10h à 19h du lundi au vendredi et le samedi de 11h à 19h, au 14 rue de Turbigo, Paris.",
        },
      },
    },
  ],
  ETAB: [
    {
      brandName: "FOOD HÔTEL TECH",
      logoSrc: "/img/partenaires/recruteurs/food-hotel-tech.png",
      ctaUrl: "https://www.foodhoteltech.com/",
      infos: {
        text: "<p>Retrouvez-nous chaque année sur le salon incontournable pour l’hôtellerie-restauration. Food Hôtel Tech rassemble tous les ans les professionnels du secteur pour découvrir les dernières tendances, solutions et outils qui révolutionnent le marché.</p>",
        promo: {
          ctaLabel: "découvrir",
        },
      },
    },
    {
      brandName: "FOODORAMA",
      logoSrc: "/img/partenaires/recruteurs/foodorama.png",
      ctaUrl:
        "https://www.servicecompris.co/foodorama-le-rendez-vous-des-food-entrepreneurs",
      infos: {
        text: "<p>Le Rendez-vous des Food-entrepreneurs ! Un événement unique qui célèbre la gastronomie et l’univers de la food avec un format original mêlant rencontres, masterclasses et dégustations.</p>",
        promo: {
          ctaLabel: "découvrir",
        },
      },
    },
    {
      brandName: "La classe verte des chefs",
      logoSrc: "/img/partenaires/recruteurs/classe-verte.png",
      ctaUrl: "https://laclassevertedeschefs.fr/fr",
      infos: {
        text: "<p>Un programme inédit qui emmène des chefs et des professionnels de la restauration au cœur de la nature pour repenser leur cuisine avec une approche durable et locale. Une expérience immersive qui valorise les circuits courts et le respect des produits.</p>",
        promo: {
          ctaLabel: "découvrir",
        },
      },
    },
  ],
};
